import img from '../../assetes/unauthorized.png'

const Unauthorized = () => {

  return (
    <div className='flex flex-col justify-center items-center p-[4rem]'>
      <img
        src={img}
        className='w-[200px] h-[auto]'
        alt="unauthorized"
      />
      <div className='text-blue-900 tracking-wider' style={{
        fontSize: '2.5vw',
        fontWeight: 'bolder'
      }}>
        Sorry, it seems that you dont have enough permissions to view this page..
      </div>
    </div>
  );
}
 
export default Unauthorized;