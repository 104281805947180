import { Navigate } from "react-router-dom";
import { shallShowTab } from "../helpers";
import Unauthorized from "../components/Home/Unauthorized";

const ProtectedRoutes = ({ moduleName, children }: {moduleName: string, children: any}) => {
  const user = localStorage.getItem("user");
  if (!user) {
    return <Navigate to="/" replace />;
  }

  if(!shallShowTab(moduleName)) {
    return <Unauthorized />
  }
  return children;
};

export default ProtectedRoutes;
