export const EmailRegexPattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const votersTabName = 'Voters Dashboard'
export const nonVotersTabName = 'Non Voters Dashboard'
export const reportsTabName = 'Reports'
export const supportersTabName = 'Supporters'
export const usersTabName = 'Users'

export const ROUTE_DASHBOARD = "/home/dashboard"
export const ROUTE_PRODUCT = "/home/product"
export const ROUTE_ORDER = "/home/order"
export const ROUTE_BANNER = "/home/banner"
export const ROUTE_CUSTOMER = "/home/customer"
export const ROUTE_CATEGORY = "/home/category"
export const ROUTE_OFFER = "/home/offer"
export const ROUTE_SUPPORT = "/home/support"
export const ROUTE_USERS = "/home/users"
export const ROUTE_DISCOUNTS_COUPONS = "/home/discounts-and-coupons"


export const MODULE_DASHBOARD = "Dashboard"
export const MODULE_PRODUCT = "Product"
export const MODULE_ORDER = "Order"
export const MODULE_BANNER = "Banner"
export const MODULE_CUSTOMER = "Customer"
export const MODULE_CATEGORY = "Category"
export const MODULE_OFFER = "Offer"
export const MODULE_SUPPORT = "Support"
export const MODULE_USERS = "Users"
export const MODULE_DISCOUNTS_COUPONS = "Discount_Coupon"


export const ROLE_ADMIN = "ADMIN"
export const ROLE_SUPER_ADMIN = "SUPER_ADMIN"
export const ROLE_MANAGER = "MANAGER"
export const ROLE_SUPPORT = "SUPPORT"
export const ROLE_SALES = "SALES"

export const LOGIN = `${process.env.REACT_APP_API_BASE_URL}/auth/login`;
export const GET_DASHBOARD_DATA = `${process.env.REACT_APP_API_BASE_URL}/dashboard/getDashboardData`;

export const GET_CUSTOMER_LIST = `${process.env.REACT_APP_API_BASE_URL}/customer/getAllProfileData`;
export const GET_CUSTOMER_BY_ID = `${process.env.REACT_APP_API_BASE_URL}/customer/getProfileDataById`
export const GET_CUSTOMER_FROM_MOBILE_NO = `${process.env.REACT_APP_API_BASE_URL}/customer/getCustomerNmByMob`
export const GET_CUSTOMER_FROM_NAME = `${process.env.REACT_APP_API_BASE_URL}/customer/getCustomerByCustomerName`
export const UPDATE_CUSTOMER = `${process.env.REACT_APP_API_BASE_URL}/customer/updateCustomer`;
export const GET_STATIC_DROPDOWN_DATA = `${process.env.REACT_APP_API_BASE_URL}/staticData/getStaticDataDD`;
export const SAVE_CATEGORY_SUBCATEGORY_LABEL = `${process.env.REACT_APP_API_BASE_URL}/staticData/saveStaticData`;
export const GET_DISCOUNT_LIST = `${process.env.REACT_APP_API_BASE_URL}/discount/getAllDiscounts`;
export const SAVE_DISCOUNTS = `${process.env.REACT_APP_API_BASE_URL}/discount/saveDiscount`;
export const GET_DISCOUNT_DATA_BY_ID = `${process.env.REACT_APP_API_BASE_URL}/discount/getDiscountById`;

export const GET_PRODUCTS = `${process.env.REACT_APP_API_BASE_URL}/product/getAllProducts`;
export const GET_PRODUCT_DATA_BY_ID = `${process.env.REACT_APP_API_BASE_URL}/product/getProductById`;
export const SAVE_PRODUCT = `${process.env.REACT_APP_API_BASE_URL}/product/saveProduct`;
export const UPDATE_STOCK_QUANTITY = `${process.env.REACT_APP_API_BASE_URL}/product/updateStockQuantity`;
export const DELETE_PRODUCT = `${process.env.REACT_APP_API_BASE_URL}/product/deleteProductById`;

export const GET_COUPONS_LIST = `${process.env.REACT_APP_API_BASE_URL}/discount/getCouponsData`;

export const GET_BANNERS = `${process.env.REACT_APP_API_BASE_URL}/banner/getAllBanners`;
export const GET_BANNER_DATA_BY_ID = `${process.env.REACT_APP_API_BASE_URL}/banner/getBannerById`;
export const CREATE_BANNER = `${process.env.REACT_APP_API_BASE_URL}/banner/saveBanner`;
export const UPDATE_BANNER = `${process.env.REACT_APP_API_BASE_URL}/banner/saveBanner`;
export const DELETE_BANNER_BY_ID = `${process.env.REACT_APP_API_BASE_URL}/banner/deleteBannerById`;

export const GET_ORDERS = `${process.env.REACT_APP_API_BASE_URL}/order/getAllOrders`;
export const GET_ORDER_DATA_BY_ID = `${process.env.REACT_APP_API_BASE_URL}/order/getProductInfoByOrderId`;
export const SAVE_ORDER = `${process.env.REACT_APP_API_BASE_URL}/order/saveOrder`;
export const BULK_UPDATE_ORDERS = `${process.env.REACT_APP_API_BASE_URL}/order/updateShippingStatusByOrderIds`;
export const GET_PAYMENT_DATA = `${process.env.REACT_APP_API_BASE_URL}/order/getPaymentDetailsByOrderId`;
export const PAYMENT_INFO_UPDATE = `${process.env.REACT_APP_API_BASE_URL}/order/updateOfflinePayment`;
// export const DELETE_ORDER = `${process.env.REACT_APP_API_BASE_URL}/order/deleteOrderById`;

export const GET_TICKETS = `${process.env.REACT_APP_API_BASE_URL}/support/getAllTickets`;
export const BULK_UPDATE_TICKETS = `${process.env.REACT_APP_API_BASE_URL}/support/saveSupportTicket`;
export const UPDATE_RESOLUTION = `${process.env.REACT_APP_API_BASE_URL}/support/updateTicketResolution`;
export const UPDATE_TICKET_STATUS_BULK = `${process.env.REACT_APP_API_BASE_URL}/support/updateTicketStatus`;

export const GET_ALL_USERS = `${process.env.REACT_APP_API_BASE_URL}/user/getAllUsers`;
export const ADD_USER = `${process.env.REACT_APP_API_BASE_URL}/user/addUser`;
export const UPDATE_USER = `${process.env.REACT_APP_API_BASE_URL}/user/updateUser`;
export const UPLOAD_DOCUMENTS = `${process.env.REACT_APP_API_BASE_URL}/invoice/uploadInvoiceFiles`;
export const UPDATE_SHIPPING_STATUS = `${process.env.REACT_APP_API_BASE_URL}/order/updateShippingStatusByOrderIds`;
export const GET_GST = `${process.env.REACT_APP_API_BASE_URL}/order/getAvgGst`;
export const GET_COUPONS = `${process.env.REACT_APP_API_BASE_URL}/discount/getActiveCouponsData`;
export const DOWNLOAD_FILE = `${process.env.REACT_APP_API_BASE_URL}/file/downloadFileById`;
export const CLONE_ORDER = `${process.env.REACT_APP_API_BASE_URL}/order/cloneOrder`;
export const UPLOAD_PRODUCT_IMAGE = `${process.env.REACT_APP_API_BASE_URL}/product/uploadProductImages`;
export const UPLOAD_CATEGORY_IMAGE = `${process.env.REACT_APP_API_BASE_URL}/staticData/uploadCategoryImages`;
export const INVOICE_DOWNLOAD = `${process.env.REACT_APP_API_BASE_URL}/invoice/downloadInvoiceByOrderId`;
export const DOWNLOAD_ORDER_EXCEL = `${process.env.REACT_APP_API_BASE_URL}/order/downloadOrderExcelByOrderId`;

export const APP_MODULES = [
  { label: "ALL", value: "ALL" },
  { label: "Dashboard", value: "Dashboard" },
  { label: "Product", value: "Product" },
  { label: "Discounts_Coupons", value: "Discounts_Coupons" },
  { label: "Banner", value: "Banner" },
  { label: "Customer", value: "Customer" },
  { label: "Order", value: "Order" },
  { label: "Category", value: "Category" },
  { label: "Users", value: "Users" },
]

export const USER_ROLES = [
  {
    label: "ADMIN",
    value: "ADMIN",
  },
  {
    label: "SUPPORT",
    value: "SUPPORT",
  },
  {
    label: "SALES",
    value: "SALES",
  },
  {
    label: "MANAGER",
    value: "MANAGER",
  },
]

export const USER_STATUS = [
  {
    label: "ACTIVE",
    value: "ACTIVE",
  },
  {
    label: "INACTIVE",
    value: "INACTIVE",
  },
]

export const ORDER_STATUS_PLACED = "Order Placed"
export const SHIPPING_STATUS_IN_TRANSIT = "In Transit"
export const SUPPORT_STATUS_INPROGRESS = "Inprogress"
export const SUPPORT_STATUS_OPEN = "Open"
export const SUPPORT_PENDING_TICKETS_VALUES = [SUPPORT_STATUS_OPEN, SUPPORT_STATUS_INPROGRESS]

export const CLEARABLE_INPUT_CLS = "clearable-input-cls"
export const STR_ORDER_FAILED = "Order Failed"
export const STR_ORDER_PLACED = "Order Placed"
