import React, { Suspense, lazy } from "react";
import AppLayout from "./components/Home/AppLayout";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Login from "./components/auth/Login";
import { ConfigProvider, theme } from "antd";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import { MODULE_BANNER, MODULE_CATEGORY, MODULE_CUSTOMER, MODULE_DASHBOARD, MODULE_DISCOUNTS_COUPONS, MODULE_OFFER, MODULE_ORDER, MODULE_PRODUCT, MODULE_SUPPORT, MODULE_USERS, ROUTE_BANNER, ROUTE_CATEGORY, ROUTE_CUSTOMER, ROUTE_DASHBOARD, ROUTE_DISCOUNTS_COUPONS, ROUTE_OFFER, ROUTE_ORDER, ROUTE_PRODUCT, ROUTE_SUPPORT, ROUTE_USERS } from "./utils/constants";
const DashboardComponent = lazy(
  () => import("./components/Dashboard/Dashboard")
);
const ProductComponent = lazy(() => import("./components/Product/Product"));
const BannerComponent = lazy(() => import("./components/Banner/Banner"));
const OrderComponent = lazy(() => import("./components/Orders/Orders"));
const CustomerComponent = lazy(() => import("./components/Customer/Customer"));
const CategoryComponent = lazy(() => import("./components/Category/Category"));
const OfferComponent = lazy(() => import("./components/Offer/Offer"));
const CouponsComponent = lazy(() => import("./components/Coupons/Coupons"));
const UserComponent = lazy(() => import("./components/Users/Users"));
const StockComponent = lazy(() => import("./components/Stocks/Stocks"));
const DiscountComponent = lazy(() => import("./components/Discounts/Discounts"));
const DiscountAndCoupons = lazy(() => import("./components/DiscountsAndCoupons"));
const Support = lazy(() => import("./components/Support"));

function App() {

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },
    {
      path: "/login",
      element: (
        <Suspense>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/home",
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <Navigate to="/home/dashboard" replace />,
        },
        {
          path: ROUTE_DASHBOARD,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_DASHBOARD}>
                <DashboardComponent />
              </ProtectedRoutes>
            </Suspense>
          ),
        },
        {
          path: ROUTE_PRODUCT,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_PRODUCT}>
                <ProductComponent />
              </ProtectedRoutes>
            </Suspense>
          ),
        },
        {
          path: ROUTE_ORDER,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_ORDER}>
                <OrderComponent />
              </ProtectedRoutes>
            </Suspense>
          ),
        },

        {
          path: ROUTE_BANNER,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_BANNER}>
                <BannerComponent />
              </ProtectedRoutes>
            </Suspense>
          ),
        },

        {
          path: ROUTE_CUSTOMER,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_CUSTOMER}>
                <CustomerComponent />
              </ProtectedRoutes>
            </Suspense>
          ),
        },
        {
          path: ROUTE_CATEGORY,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_CATEGORY}>
                <CategoryComponent />
              </ProtectedRoutes>
            </Suspense>
          ),
        },
        {
          path: ROUTE_OFFER,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_OFFER}>
                <OfferComponent />
              </ProtectedRoutes>
            </Suspense>
          ),
        },
        {
          path: ROUTE_SUPPORT,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_SUPPORT}>
                <Support />
              </ProtectedRoutes>
            </Suspense>
          ),
        },
        {
          path: ROUTE_USERS,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_USERS}>
                <UserComponent />
              </ProtectedRoutes>
            </Suspense>
          ),
        },
        // {
        //   path: ROUTE_STOCKS,
        //   element: (
        //     <Suspense fallback={<h1>Loading...</h1>}>
        //       <ProtectedRoutes moduleName={MODULE_STOCKS}>
        //         <StockComponent />
        //       </ProtectedRoutes>
        //     </Suspense>
        //   ),
        // },
        {
          path: ROUTE_DISCOUNTS_COUPONS,
          element: (
            <Suspense fallback={<h1>Loading...</h1>}>
              <ProtectedRoutes moduleName={MODULE_DISCOUNTS_COUPONS}>
                <DiscountAndCoupons />
              </ProtectedRoutes>
            </Suspense>
          ),
        },
      ],
    },
  ]);

  return (
    <div className="h-full">
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Inter",
          }
        }}
      >
        <RouterProvider router={appRouter} />
      </ConfigProvider>
    </div>
  );
}

export default App;
