export const HTTP_POST_AUTH = async (url: string, body: any) => {
  try {
    const data = (await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })) as any;

    const res = await data.json();
    if (res.statusCodeValue === 200) {
      return res.body;
    }
    return { status: "invalid" };
  } catch (error) {
    return { status: "error" };
  }
};

const getToken = () => {
  const user = localStorage.getItem("user");
  if (user) {
    const token = JSON.parse(user).token;
    return token;
  }

  return null;
};

export const HTTP_GET = async (url: string) => {
  try {
    const data = await fetch(url, {
      method: "GET",
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    });

    const res = await data.json();
    if (res.statusCodeValue === 200) {
      return res.body;
    }
    return { status: "invalid" };
  } catch (error) {
    return { status: "error" };
  }
};


export const HTTP_POST_MULTIPART = async (url: string, formData: any) => {
  try {
    const data = await fetch(url, {
      method: "POST",
      body: formData,
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    });

    const res = await data.json();
    if (res.statusCodeValue === 200) {
      return res;
    }
    return { status: "invalid" };
  } catch (error) {
    return { status: "error" };
  }
};



export const handler = async (url: string, method: "POST" | "PUT" | "DELETE", body?: any, type?: 'listing') => {
  try {
    let payload: RequestInit = {
      method,
      headers: new Headers({
        "content-type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    }

    if (body) {
      payload = {
        ...payload,
        body: JSON.stringify(body),
      }
    }
    const data = await fetch(url, payload);

    const res = await data.json();
   
    if (res.statusCodeValue === 200) {
      if(!res.body) {
        if (type === 'listing') {
          return  []
        }
        return {
          statusCode: 'OK',
        }
      }
      return res.body;
    }
    if (res.statusCodeValue === 302) {
      if(res.message) {
        return {
          statusCodeValue: 302,
          message: res.message
        }
      }
      return res.body;
    }
    return { status: "invalid" };
  } catch (error) {
    return { status: "error" };
  }
};

export const HTTP_POST = async (url: string, body?: any, type?: "listing") => handler(url, "POST", body, type)
export const HTTP_PUT = async (url: string, body: any) => handler(url, "PUT", body)
export const HTTP_DELETE = async (url: string) => handler(url, "DELETE", null)