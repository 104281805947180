import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface OptionsState {
  refreshOptions: boolean
  labelList: any[]
  brandList: any[]
  categoriesList: any[]
  subCategoriesList: any[]
  unitsList: any[]
  discountAppliedOnList: any[]
  discountTypeList: any[]
  rolesList: any[]
  modulesList: any[]
  paymentModeList: any[]
  paymentStatusList: any[]
  orderStatusList: any[]
  ticketStatusList: any[]
  orderPaymentTypeList: any[]
  shippingStatusList: any[]
}

const initialState: OptionsState = {
  refreshOptions: false,
  labelList: [],
  brandList: [],
  categoriesList: [],
  subCategoriesList: [],
  unitsList: [],
  discountAppliedOnList: [],
  discountTypeList: [],
  rolesList: [],
  modulesList: [],
  paymentModeList: [],
  paymentStatusList: [],
  orderStatusList: [],
  ticketStatusList: [],
  orderPaymentTypeList: [],
  shippingStatusList: [],
}

type stateNames = keyof OptionsState
type optionNames = Exclude<stateNames, "refreshOptions">

export const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    setOptionsState: (state, action: PayloadAction<{ name: optionNames , value: any[] }>) => {
      state[action.payload.name] = action.payload.value
    },
    setRefreshOptions: (state, action: PayloadAction<boolean>) => {
      state.refreshOptions = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setOptionsState, setRefreshOptions } = optionsSlice.actions

export default optionsSlice.reducer