import React, { useEffect, useState } from "react";
import { AutoComplete, Input, Dropdown } from "antd";
import { LuSearch } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";

const Header = () => {
  var [isScrollAtTop, setIsScrollAtTop] = useState(true);
  const [user, setUser] = useState(null) as any;

  useEffect(() => {
    const user = localStorage.getItem("user");
    if(user){
      setUser(JSON.parse(user))
    }
  }, [])

  const [options, setOptions] = useState<{ value: string }[]>([
    { value: "Dashboard" },
    { value: "Product" },
    { value: "Banner" },
    { value: "Order" },
    { value: "Customer" },
    { value: "Category" },
    { value: "Offer" },
    { value: "Coupons" },
    { value: "Users" },
    { value: "Stocks" },
  ]);


  const signOut = () => {
    localStorage.removeItem("user");
    navigate("/");
  }

  const items: MenuProps["items"] = [
    {
      label: <span className="font-semibold">{user?.firstName} {user?.lastName}</span>,
      key: "0",
    },
    {
      label: <span onClick={signOut}>Sign Out</span>,
      key: "1",
    },
  ];

  const navigate = useNavigate();

  const onSelect = (data: string) => {
    switch (data) {
      case "Dashboard":
        navigate("/home/dashboard");
        break;
      case "Product":
        navigate("/home/product");
        break;

      case "Customer":
        navigate("/home/customer");
        break;

      case "Banner":
        navigate("/home/banner");
        break;

      case "Order":
        navigate("/home/order");
        break;

      case "Category":
        navigate("/home/category");
        break;

      case "Offer":
        navigate("/home/offer");
        break;

      case "Coupons":
        navigate("/home/coupons");
        break;

      case "Users":
        navigate("/home/users");
        break;

      case "Stocks":
        navigate("/home/stocks");
        break;

      default:
        break;
    }
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY === 0) {
      setIsScrollAtTop(true);
    } else {
      setIsScrollAtTop(false);
    }
  });

  return (
    <div
      className={` ${
        isScrollAtTop ? null : "shadow-md"
      } border-b border-slate-200 h-16 flex items-center justify-end px-5 fixed z-10 bg-white top-0 left-0 right-0 ml-64`} style={{width: "calc(100% - 16rem)"}}
    >
      {/* <AutoComplete
        className="!w-96"
        options={options}
        style={{ width: 200 }}
        onSelect={onSelect}
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      >
        <Input
          size="large"
          placeholder="Search your page..."
          prefix={<LuSearch className="mr-1" />}
        />
      </AutoComplete> */}

      <Dropdown menu={{ items }} trigger={["click"]}>
        <img
          src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-11.webp"
          className="w-10 rounded-full cursor-pointer"
        />
      </Dropdown>
    </div>
  );
};

export default Header;
