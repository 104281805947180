import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { initialStateRouteParams } from '../helpers'

export type IStateRouteParams = {
  status: string
  shippingStatus: string
  ticketStatus: string[]
  isActive: string
  activeTab: string
}

export interface CommonState {
  stateRouteParams: IStateRouteParams
  reloadClicked: boolean
}

export type PayloadType = {
  name: "stateRouteParams",
  value: IStateRouteParams 
} | {
  name: "reloadClicked",
  value: boolean 
}

const initialState: CommonState = {
  stateRouteParams: {...initialStateRouteParams},
  reloadClicked: false
}

export const commonSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    setReduxState: (state, action: PayloadAction<PayloadType>) => {
      //@ts-ignore
      state[action.payload.name] = action.payload.value
    }
  },
})

export const { setReduxState } = commonSlice.actions

export default commonSlice.reducer