import { MODULE_BANNER, MODULE_CATEGORY, MODULE_CUSTOMER, MODULE_DASHBOARD, MODULE_DISCOUNTS_COUPONS, MODULE_OFFER, MODULE_ORDER, MODULE_PRODUCT, MODULE_SUPPORT, MODULE_USERS, ORDER_STATUS_PLACED, ROUTE_BANNER, ROUTE_CATEGORY, ROUTE_CUSTOMER, ROUTE_DASHBOARD, ROUTE_DISCOUNTS_COUPONS, ROUTE_OFFER, ROUTE_ORDER, ROUTE_PRODUCT, ROUTE_SUPPORT, ROUTE_USERS, SHIPPING_STATUS_IN_TRANSIT, SUPPORT_PENDING_TICKETS_VALUES } from "./utils/constants"


export const VAR_PERCENTAGE = "Percentage"
export const VAR_FLAT = "Flat"

export const productFormInitialValues = {
  brandId: null,
  brandName: "",
  category: null,
  description: "",
  discountPercent: 0,
  discountCategory: VAR_PERCENTAGE,
  flatDiscountAmount: "",
  feature: "",
  images: [],
  files: [],
  deletedImageIds: "",
  isActive: "N",
  isDiscountActive: "N",
  labelIds: [],
  labelList: [],
  minPriceForOrder: null,
  minQuantityForOrder: 1,
  minWeightForFreeShippingInGm: null,
  minWeightForOrderInGm: null,
  price: null,
  productInfoId: null,
  productName: "",
  rating: "",
  size: "",
  stockQuantity: 0,
  subCategory: null,
  totalCount: null,
  unit: null,
  useByDate: null,
  weightInGm: null
}

export const bannerFormInitialValues = {
  src: "",
  title: "",
  redirectUrl: "",
  startDate: null,
  endDate: null,
  description: "",
  status: "Y",
  fileType: "",
  size: null,
  width: null,
  height: null,
  aspectRatio: "",
}


export const userFormInitialValues = {
  userId: null,
  username: "",
  password: "",
  firstNm: "",
  lastNm: "",
  email: "",
  mobileNumber: "",
  status: "",
  role: "",
  modules: ""
}


export const initialStateRouteParams = {
  status: '',
  shippingStatus: '',
  ticketStatus: [],
  isActive: '',
  activeTab: ""
}

export const orderFiltersInitialValues = {
  customerIdArray: [],
  status: [],
  shippingStatus: [],
  orderPaymentType: [],
  dateRange: null,
  common: ''
}

export const supportFiltersInitialValues = {
  dateRange: null,
  status: []
}


export const LS_key_user = "user" 

export const getUserData = () => {

  try {
    if(localStorage.getItem(LS_key_user)) {
      return JSON.parse(localStorage.getItem(LS_key_user) ?? '')
    }
    return {
      role: '',
      modules: ''
    }
  } catch (e) {
    return {
      role: '',
      modules: ''
    }
  }
}

export const ls_isAdminUser = getUserData().role && getUserData().role.toLowerCase().indexOf('admin') !== -1
export const ls_isSuperAdminUser = getUserData().role && getUserData().role.toLowerCase().indexOf('superadmin') !== -1
export const ls_isManagerUser = getUserData().role && getUserData().role.toLowerCase().indexOf('manager') !== -1
export const ls_isSupportUser = getUserData().role && getUserData().role.toLowerCase().indexOf('support') !== -1
export const ls_isSalesUser = getUserData().role && getUserData().role.toLowerCase().indexOf('sales') !== -1


export let ls_allModules = getUserData().modules && getUserData().modules.split(',')
// .filter((s: string) => !['Support'].includes(s))

export const setAllLsModules = () => {
  ls_allModules = getUserData().modules && getUserData().modules.split(',')
  // .filter((s: string) => !['Support'].includes(s))
}

export const shallShowTab = (tabName: string) => {
  return ls_allModules.includes(tabName)
}

export const getRoute = (moduleName: string) => {
  switch(moduleName) {
    case MODULE_DASHBOARD: return ROUTE_DASHBOARD
    case MODULE_PRODUCT: return ROUTE_PRODUCT
    case MODULE_ORDER: return ROUTE_ORDER
    case MODULE_BANNER: return ROUTE_BANNER
    case MODULE_CUSTOMER: return ROUTE_CUSTOMER
    case MODULE_CATEGORY: return ROUTE_CATEGORY
    case MODULE_OFFER: return ROUTE_OFFER
    case MODULE_SUPPORT: return ROUTE_SUPPORT
    case MODULE_USERS: return ROUTE_USERS
    case MODULE_DISCOUNTS_COUPONS: return ROUTE_DISCOUNTS_COUPONS
    default: return ROUTE_DASHBOARD
  } 
}

export const getBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const getFile = async (url: any, name: string) => {
  const res: Response = await fetch(url);
  const blob: Blob = await res.blob();
  return new File([blob], name, { type: url.match(/^data:(.+);base64/)?.[1] });
}

export const getImageBase64Name = (image: any, keyName?: string) => {
  const prepend = `data:image/png;base64,`

  if(typeof image == 'string' && image.indexOf("https") != -1){
    return image;
  }

  if((image.document == undefined || image.document == null) && image?.documentPath){
    return `${process.env.REACT_APP_IMAGE_PATH}${image?.documentPath}`;
  }
  let base64Img = image.document

  // if (keyName === "") base64Img = image
  if (keyName === "") base64Img = !image?.document ? image : image.document;

  if(base64Img && base64Img.indexOf('data:') === -1) return `${prepend}${base64Img}`
  return `${base64Img}`
}

export interface ProductDataType {
  key: string
  productName: string
  brandId: string
  brandName: string
  category: string
  description: string
  discountPercent: string
  feature: string
  images: string[]
  isActive: string
  isDiscountActive: string
  labelIds: number[]
  labelList: string[]
  minPriceForOrder: string
  minQuantityForOrder: string
  minWeightForFreeShippingInGm: string
  minWeightForOrderInGm: string
  price: string
  productInfoId: string
  rating: string
  size: string
  stockQuantity: string
  subCategory: string
  totalCount: string
  unit: string
  useByDate: string
  weightInGm: string
}

export interface DiscountDataType {
  discountId: number
  discountType: number
  number: number
  discountInPer: number
  flatDiscountAmount: number
  isActive: boolean
  discountApplyOn: number
  discountTypeValue: string
  discountApplyOnValue: string
  couponDiscountCode: string
  totalCount: number
}

export interface BannerDataType {
  src: string
  title: string
  redirectUrl: string
  startDate: string
  endDate: string
  description: string
  isActive: string
  fileType: string
  size: number
  width: number
  height: number
  aspectRatio: string
}

export interface UserDataType {
  userId: number
  username: string
  password: string
  firstNm: string
  mobileNumber: string
  lastNm: string
  email: string
  status: string
  role: string
  modules: string
}

export interface OrderDataType {
  amount: number
  amountDue: number
  amountPaid: number
  billAmountDiscountCode: string
  billDiscountAmount: number
  billingAddress: string
  couponDiscountAmount: number
  couponDiscountCode: string
  currency: string
  customerDiscountAmount: number
  customerId: number
  customerName: string
  entity: string
  finalAmount: number
  gstAmount: number
  gstPercent: number
  mobileNumber: string
  notes: string
  offerId: string
  orderInfoId: number
  orderNo: string
  paymentRefNo: string
  products: string
  razorpayOrderNo: string
  receipt: string
  shippingAddress: string
  shippingStatus: string
  status: string
  totalBillAmount: number
  totalBillAmountAfterDiscount: number
  totalCount: number
}
export interface ProductInOrderDataType {
  orderProductInfoId: number
  productId: number
  productName: string
  category: number
  subCategory: number
  price: number
  quantityInDozen: number
  totalProductPrice: number
  hsnCode: string
}

export interface PaymentDetailsDataType {
  paymentId: number
  paymentNo: string
  orderInfoId: number
  orderNo: string | null
  entity: string
  amount: number
  currency: string
  invoiceId: string | null
  international: string
  paymentMethod: string
  amountRefunded: string | null
  refundStatus: string | null
  captured: string
  description: string | null
  cardId: string | null
  bank: string | null
  wallet: string | null
  vpa: string | null
  email: string | null
  contact: string | null
  notes: string | null
  fee: string | null
  tax: string | null
  errorCode: string | null
  errorDescription: string | null
  errorSource: string | null
  errorStep: string | null
  errorReason: string | null
  acquirerData: string | null
  upi: string | null
  bankCustomerId: string | null
  tokenId: string | null
  status: string
  razorpayPaymentNo: string | null
  paymentRefNo: string
  offlinePaymentMode: string
}

export interface TicketDataType {
  supportId: number
  customerId: String
  mobileNumber: string
  requestMsg: string
  responseMsg: string
  status: string
  custName: string
  totalCount: number
}

export interface SupportDataType {
  customerName: string
  emailId: string
  gstNumber: string
  panNumber: string
  isCreditCustomer: string
}



export type ProductDataIndex = keyof ProductDataType;
export type DiscountDataIndex = keyof DiscountDataType;
export type BannerDataIndex = keyof BannerDataType;
export type UserDataIndex = keyof UserDataType;
export type OrderDataIndex = keyof OrderDataType;
export type ProductInOrderDataIndex = keyof ProductInOrderDataType;
export type PaymentDetailsDataIndex = keyof PaymentDetailsDataType;
export type TicketDataIndex = keyof TicketDataType;
export type SupportDataIndex = keyof SupportDataType;

export type DataIndex = ProductDataIndex | DiscountDataIndex | BannerDataIndex | UserDataIndex |
  OrderDataIndex | ProductInOrderDataIndex | PaymentDetailsDataIndex | TicketDataIndex | SupportDataIndex;

export type ProductRecordType = Partial<{ productName: string, productInfoId: number }>
export type BannerRecordType = Partial<{ bannerId: number, title: string }>
export type OrderRecordType = Partial<{ orderId: number }>

export type RecordModalProps = {
  data: any
  handleSubmitForm: (values: any) => Promise<any>
  handleCancel: () => void
}

export const statusList: any[] = [
  {
    label: "Completed",
    value: "Completed"
  },
  {
    label: "Payment IP",
    value: "Payment IP"
  },
  {
    label: "Ordered",
    value: "Ordered"
  },
]

export type IDeleteRecordPopup = {
  type: "product"
  record: ProductRecordType | null
  onOk: () => void
  onCancel: () => void
} | {
  type: "banner"
  record: BannerRecordType | null
  onOk: () => void
  onCancel: () => void
} | {
  type: "order"
  record: OrderRecordType | null
  onOk: () => void
  onCancel: () => void
}

export const optionsTypeFields = ['brandName', 'category', 'subCategory', 'discountType', 'discountApplyOn', 'status', 'isActive', 'labelList', 'isCreditCustomer']
export const dateTypeFields = ['useByDate']
export const floatTypeFields = [
  /** Product table */
  'weightInGm', 'size', 'price', 'stockQuantity',

  /** Discount/Coupon table */
  'number', 'discountInPer', 'flatDiscountAmount',

  /** Support table */
  'supportId'
]

export const getFieldName = (fieldName: DataIndex) => {
  if(fieldName === 'brandName') return "brandId"
  if(fieldName === 'labelList') return "labelIds"
  return fieldName
}

export const VAR_DISCOUNT = "Discount"
export const VAR_COUPON = "Coupon"
export const MIN_ASPECT_RATIO = 1.49
export const FORMAT_IMAGE = "image"
export const FORMAT_VIDEO = "video"

export const DiscountCategoryList = [
  {
    label: "Flat",
    value: "Flat"
  },
  {
    label: "Percentage",
    value: "Percentage"
  }
]

export const YesNoList = [
  {
    label: "Yes",
    value: "Y"
  },
  {
    label: "No",
    value: "N"
  }
]

export const MAX_STATIC_DISCOUNT = 100

export const handleCopy = (e: any, callback: () => void) => {
  if (e.target.innerText) {
    navigator.clipboard.writeText(e.target.innerText);
    callback()
  }
}

export const formatWithOneSpaceWithCamelCase = (str: string) => {

  return str.replace(/\s+/g, ' ').split(' ').map((s: string) => `${s.charAt(0).toUpperCase()}${s.substring(1).toLowerCase()}`).join(' ');
}

export const testAlphaNumericWithPeriod = (str: string) => {

  if(str === "") return true
  
  if (/^[a-zA-Z0-9-_\. ]+$/.test(str)) {
    return true
  }
  return false

}

export const getAmountCorresToPrcnt = (amt: number, prcnt: number) => {

  let result = ((amt * prcnt) / 100).toFixed(2)
  return result
}

export const validImageTypes = ['jpg', 'png', 'webp', 'jpeg']

export async function getImageDetails(file: any) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = function(event) {
          const image = new Image();
          image.onload = function() {

              const details = {
                  width: image.width,
                  height: image.height,
                  type: file.type,
                  name: file.name,
                  size: file.size
              };
              resolve(details);
          };
          image.onerror = function() {
              reject(new Error("Failed to load image."));
          };
      };

      reader.onerror = function() {
          reject(new Error("Failed to read file."));
      };

      reader.readAsDataURL(file);
  });
}

export function formatFileSize(bytes: number) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const isYoutubeUrlValid = (str: string) => {
  if (!str) return false

  if (str.toLowerCase().includes('.youtube.com')) return true
  
  return false
}

export const getEmbedVideoLink = (str: string) => {
  if (!str) return ""

  return str.replace('/watch?', '/embed?')
}

export type RuleObject = {
  name: string,
  rule: RegExp,
  fulfilled: boolean,
}

export const getStaticValidationScheme = () => {

  let initialSequence = [
    {
      name: "8 characters",
      rule: /^.{8,}$/,
      fulfilled: false,
    },
    {
      name: "1 uppercase letter",
      rule: /^(?=.*?[A-Z]).{1,}$/,
      fulfilled: false,
    },
    {
      name: "1 lowercase letter",
      rule: /^(?=.*?[a-z]).{1,}$/,
      fulfilled: false,
    },
    {
      name: "1 number",
      rule: /.*[0-9].*/,
      fulfilled: false,
    },
    {
      name: "1 special character",
      rule: /^(?=.*?[#?!@$%^&*-]).{1,}$/,
      fulfilled: false,
    },
  ]

  return initialSequence
};

const imageStrings = ['image', 'jpeg', 'png', 'svg', 'img', 'webp']
const videoStrings = ['video', 'mp4', '3gp']

export const getBannerFormatFromParam  = (str: string) => {
  if(!str) return "image"

  if (videoStrings.some((s: string) => str.toLowerCase().includes(s.toLowerCase()))) return "video"

  return "image"
}

export const orderStatusMappings = {


  start: [
    "placed", "ready"
  ],
  inProgress: [
    "created", "in transit", 
  ],
  paymentGood: [
    "created", "shipped"
  ],
  success: [
    "out for delivery"
  ],
  bad: [
    "failed"
  ],

  end: [
    "Out for Delivery", "delivered", "Returned",
  ]

}

export function groupBy(array: any[], key: string) {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
}

export const isMobileNumberValid = (mobNo: string) => {
  if(!/^[0-9]+$/.test(mobNo) ) {
    return false
  }

  if(mobNo.length > 10) {

    return false
  }

  return true
}

export const handleMobileNumberChange = (e: any, callback: () => void) => {

  if(!e.target.value) callback()

  if(isMobileNumberValid(e.target.value)) {
    callback()
  }
}

export const ticketStatusStaticList = [
  { label: "Open", value: 1 },
  { label: "WIP", value: 2 },
  { label: "Resolved", value: 3 },
]

export const orderTypeStaticList = [
  { label: "Offline", value: 1 },
  { label: "Razorpay", value: 2 },
]

export function formatRequestString(input: string) {
  if (!input) return ''

  return input.replace(/\n/g, '<br>').replace(/\/n/g, '<br>');
}

export const getRouteBasedFilters = ({
  stateRouteParams,
  orderStatusList,
  shippingStatusList,
  ticketStatusList,
}: {
  stateRouteParams: any
  orderStatusList?: any[]
  shippingStatusList?: any[]
  ticketStatusList?: any[]
}) => {
  let defaultValues: any = {}

    if (orderStatusList && stateRouteParams?.status === ORDER_STATUS_PLACED) {
      const matchingVal = orderStatusList.find((item) => item.label === stateRouteParams.status)?.value

      if (matchingVal) {
        defaultValues = {
          ...defaultValues,
          status: [matchingVal],
          
        }
      }
    }
  
    if (shippingStatusList && stateRouteParams?.shippingStatus === SHIPPING_STATUS_IN_TRANSIT) {
      const matchingVal = shippingStatusList.find((item) => item.label === stateRouteParams.shippingStatus)?.value

      if (matchingVal) {
        defaultValues = {
          ...defaultValues,
          shippingStatus: [matchingVal],
        }
      }
    }
  
    if (ticketStatusList) {
      const matchingValArray = ticketStatusList.filter((item) => item?.label && SUPPORT_PENDING_TICKETS_VALUES.includes(item.label))

      if (matchingValArray?.[0]?.label) {
        defaultValues = {
          ...defaultValues,
          status: [...matchingValArray],
        }
      }
    }
  
    if (stateRouteParams?.isActive === "Y") {
      
        defaultValues = {
          ...defaultValues,
          isActive: "Y",
        }
    }
  
    if (stateRouteParams?.status === "Y") {
      
        defaultValues = {
          ...defaultValues,
          status: "Y",
        }
    }

    return defaultValues
}

export const resetClearableInputContent = () => {
  try {

    const inputs = document.querySelectorAll(`input.clearable-input-cls`);

    // Loop through all input elements and set their value to an empty string
    inputs.forEach(input => {
      (input as HTMLInputElement).value = '';
    });
  } catch (e) {
    console.log('Error in resetClearableInputContent: ', e)
  }
}

export function isFloat(str: string) {
  const num = parseFloat(str);
  return !isNaN(num) && num.toString() === str.trim();
}