import React, { lazy, useEffect, useState } from "react";
import Header from "./Header";
import {
  LuLineChart,
  LuScrollText,
  LuUserPlus2,
  LuTicket,
  LuPackageOpen,
  LuHome,
  LuUsers2,
  LuShoppingBag,
  LuLayoutDashboard,
  LuShoppingCart,
  LuBadgeDollarSign,
  LuHelpingHand,
  LuCopyright,
} from "react-icons/lu";
import logo from "../../assetes/logo.svg";
import {
  Link,
  NavLink,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HTTP_POST } from "../../utils/http-service";
import {
  GET_STATIC_DROPDOWN_DATA,
  MODULE_BANNER,
  MODULE_CATEGORY,
  MODULE_CUSTOMER,
  MODULE_DASHBOARD,
  MODULE_DISCOUNTS_COUPONS,
  MODULE_OFFER,
  MODULE_ORDER,
  MODULE_PRODUCT,
  MODULE_SUPPORT,
  MODULE_USERS,
  ROUTE_BANNER,
  ROUTE_CATEGORY,
  ROUTE_CUSTOMER,
  ROUTE_DASHBOARD,
  ROUTE_DISCOUNTS_COUPONS,
  ROUTE_OFFER,
  ROUTE_ORDER,
  ROUTE_PRODUCT,
  ROUTE_SUPPORT,
  ROUTE_USERS,
} from "../../utils/constants";
import { message } from "antd";
import { setOptionsState, setRefreshOptions } from "../../slices/optionsSlice";
import { shallShowTab } from "../../helpers";
import { FaHandsHelping, FaQuestionCircle } from "react-icons/fa";
import { RootState } from "../../store";

const AppLayout = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { refreshOptions } = useSelector((state: RootState) => state.options);

  const [menuList, setMenuList] = useState([
    {
      title: "Dashboard",
      module: MODULE_DASHBOARD,
      path: ROUTE_DASHBOARD,
      icon: <LuHome className="text-lg text-red-600" />,
    },
    {
      title: "Product",
      module: MODULE_PRODUCT,
      path: ROUTE_PRODUCT,
      icon: <LuShoppingBag className="text-lg text-blue-600" />,
    },
    {
      title: "Discounts & Coupons",
      module: MODULE_DISCOUNTS_COUPONS,
      path: ROUTE_DISCOUNTS_COUPONS,
      icon: <LuBadgeDollarSign className="text-lg text-green-600" />,
    },
    {
      title: "Banner",
      module: MODULE_BANNER,
      path: ROUTE_BANNER,
      icon: <LuScrollText className="text-lg text-purple-600" />,
    },
    {
      title: "Customer",
      module: MODULE_CUSTOMER,
      path: ROUTE_CUSTOMER,
      icon: <LuUsers2 className="text-lg text-rose-600" />,
    },
    {
      title: "Orders",
      module: MODULE_ORDER,
      path: ROUTE_ORDER,
      icon: <LuShoppingCart className="text-lg text-orange-600" />,
    },
    {
      title: "Modify Categories",
      module: MODULE_CATEGORY,
      path: ROUTE_CATEGORY,
      icon: <LuLayoutDashboard className="text-lg text-yellow-600" />,
    },
    {
      title: "Offer",
      module: MODULE_OFFER,
      path: ROUTE_OFFER,
      icon: <LuPackageOpen className="text-lg text-green-600" />,
    },
    {
      title: "Support",
      module: MODULE_SUPPORT,
      path: ROUTE_SUPPORT,
      icon: <FaQuestionCircle className="text-lg text-green-600" />,
    },
    {
      title: "Users",
      module: MODULE_USERS,
      path: ROUTE_USERS,
      icon: <LuUserPlus2 className="text-lg text-purple-600" />,
    },
    // {
    //   title: "Stocks",
    //   path: "/home/stocks",
    //   icon: <LuLineChart className="text-lg text-teal-900" />,
    // },
  ]);

  const getOptionsList = async () => {
    const data = await HTTP_POST(`${GET_STATIC_DROPDOWN_DATA}`, {});
    if (data != null && data["status"] !== undefined) {
      if (data["status"] === "invalid") {
        messageApi.error("Something went wrong!!");
      } else if (data["status"] === "error") {
        messageApi.error(
          "Error in Getting list of Category/Subcategory/Labels Details!!"
        );
      }
    } else {
      data["Role"] &&
        dispatch(
          setOptionsState({
            name: "rolesList",
            value: data["Role"]?.map((item: any) => {
              return {
                label: item?.dropDrownValue,
                value: `${item?.dropDrownValue}`,
              };
            }),
          })
        );

      dispatch(
        setOptionsState({
          name: "modulesList",
          value: data["Modules"]?.map((item: any) => {
            return {
              label: item?.dropDrownValue,
              value: `${item?.dropDrownValue}`,
            };
          }),
        })
      );

      dispatch(
        setOptionsState({
          name: "discountAppliedOnList",
          value: data["Discount Apply On"]?.map((item: any) => {
            return {
              label: item?.dropDrownValue,
              value: `${item?.dropDownId}`,
            };
          }),
        })
      );

      dispatch(
        setOptionsState({
          name: "discountTypeList",
          value: data["Discount Type"]?.map((item: any) => {
            return {
              label: item?.dropDrownValue,
              value: `${item?.dropDownId}`,
            };
          }),
        })
      );

      data["Label"] &&
        dispatch(
          setOptionsState({
            name: "labelList",
            value: data["Label"]?.map((item: any) => {
              return {
                label: item?.dropDrownValue,
                value: `${item?.dropDownId}`,
              };
            }),
          })
        );
      dispatch(
        setOptionsState({
          name: "brandList",
          value: data["Brand"]?.map((item: any) => {
            return {
              label: item?.dropDrownValue,
              value: `${item?.dropDownId}`,
            };
          }),
        })
      );
      dispatch(
        setOptionsState({
          name: "unitsList",
          value: data["Product Units"]?.map((item: any) => {
            return {
              label: item?.dropDrownValue,
              value: `${item?.dropDownId}`,
            };
          }),
        })
      );
      dispatch(
        setOptionsState({
          name: "paymentModeList",
          value: data["PAYMENT_MODE"]?.map((item: any) => {
            return {
              label: item?.dropDrownValue,
              value: `${item?.dropDownId}`,
            };
          }),
        })
      );
      dispatch(
        setOptionsState({
          name: "paymentStatusList",
          value: data["Payment_Status"]?.map((item: any) => {
            return {
              label: item?.dropDrownValue,
              value: `${item?.dropDownId}`,
            };
          }),
        })
      );
      data?.["Ticket Status"] &&
        dispatch(
          setOptionsState({
            name: "ticketStatusList",
            value: data["Ticket Status"]?.map((item: any) => {
              return {
                label: item?.dropDrownValue,
                value: `${item?.dropDownId}`,
              };
            }),
          })
        );
      data?.["Order Status"] &&
        dispatch(
          setOptionsState({
            name: "orderStatusList",
            value: data["Order Status"]?.map((item: any) => {
              return {
                label: item?.dropDrownValue,
                value: `${item?.dropDownId}`,
              };
            }),
          })
        );
      data?.["Order Payment Type"] &&
        dispatch(
          setOptionsState({
            name: "orderPaymentTypeList",
            value: data["Order Payment Type"]?.map((item: any) => {
              return {
                label: item?.dropDrownValue,
                value: `${item?.dropDownId}`,
              };
            }),
          })
        );
      data["Shipping Status"] && dispatch(
        setOptionsState({
          name: "shippingStatusList",
          value: data["Shipping Status"]?.map((item: any) => {
            return {
              label: item?.dropDrownValue,
              value: `${item?.dropDownId}`,
            };
          }),
        })
      );

      const formmatedCategoreis = data["Category"]?.map((item: any) => {
        return {
          label: item?.dropDrownValue,
          value: `${item?.dropDownId}`,
        };
      });
      dispatch(
        setOptionsState({
          name: "categoriesList",
          value: formmatedCategoreis,
        })
      );

      const formmatedSubCategoriesList = data["Sub Category"]?.map(
        (item: any) => {
          return {
            label: item?.dropDrownValue,
            value: `${item?.dropDownId}`,
            parentDropDrownId: `${item?.parentDropDrownId}`,
          };
        }
      );

      dispatch(
        setOptionsState({
          name: "subCategoriesList",
          value: formmatedSubCategoriesList,
        })
      );
    }
  };

  useEffect(() => {
    getOptionsList();
  }, []);

  useEffect(() => {
    if (refreshOptions) {
      getOptionsList();

      setTimeout(() => {
        dispatch(setRefreshOptions(false));
      }, 500);
    }
  }, [refreshOptions]);

  return (
    <div className="h-full">
      <div className="flex flex-col h-full relative">
        <div className="flex h-full relative">
          <div className="w-64 border-r border-slate-200 flex flex-col fixed bg-white z-10 h-full">
            <div className="font-medium flex items-center justify-center p-5">
              <p
                className="text-2xl abc cursor-pointer"
                onClick={(e) => navigate("/home")}
              >
                <img src={logo} className="w-full h-8" />
              </p>
            </div>

            <div className="flex flex-col px-5">
              {menuList.map((item, index) => {
                if (shallShowTab(item.module)) {
                  return (
                    <NavLink
                      key={`${item.title}_${index}`}
                      to={item.path}
                      className={({ isActive }) =>
                        isActive
                          ? "text-blue-700 bg-slate-100 rounded-md"
                          : "text-gray-800"
                      }
                    >
                      <div className="font-medium flex text-sm gap-3 items-center justify-start px-5 h-10 cursor-pointer  hover:bg-slate-100 rounded-md">
                        {item.icon} <span>{item.title}</span>
                      </div>
                    </NavLink>
                  );
                }
                // return <></>;
              })}
            </div>
          </div>

          <div
            className="h-full relative ml-64"
            style={{ width: "calc(100% - 16rem)" }}
          >
            <Header />
            <div className="px-5 mt-[4.5rem] pb-8">
              <Outlet />
            </div>
          </div>
        </div>
        <div className="border-t bg-white py-1 text-sm w-full fixed flex justify-center items-center z-10 bottom-0">
          <LuCopyright /> &nbsp;{new Date().getFullYear()} Flint Infotech LLP.
          All rights reserved
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
