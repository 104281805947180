import { Button, Form, Input, Card, message, Spin } from "antd";
import React, { useState } from "react";
import { useFormik } from "formik";
import { HTTP_POST_AUTH } from "../../utils/http-service";
import { LOGIN } from "../../utils/constants";
import page_1 from "../../assetes/page_1.png";
import page_2 from "../../assetes/page_2.png";
import page_3 from "../../assetes/page_3.png";
import { useNavigate } from "react-router-dom";
import { getRoute, ls_allModules, setAllLsModules } from "../../helpers";

const Login = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const URL = `${LOGIN}`;
    const data = await HTTP_POST_AUTH(URL, values);
    if (data["status"] !== undefined) {
      if (data["status"] === "invalid") {
        messageApi.error("Please check username or password!!");
      } else if (data["status"] === "error") {
        messageApi.error("Something went wrong!!");
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      localStorage.setItem("user", JSON.stringify(data));
      setAllLsModules()

      let defaultRoute = "/home/dashboard"

      if (ls_allModules && ls_allModules.length > 0) defaultRoute = getRoute(ls_allModules[0])
      navigate(defaultRoute);
    }
  };

  return (
    <div className="flex flex-row relative justify-center h-full w-full">
      {/* <img
        src={sunset}
        className="absolute top-0 bottom-0 left-0 right-0 overflow-hidden h-full w-full"
      /> */}

      {contextHolder}
      <div className="h-full w-[60%] relative p-5">
        <div className="bg-slate-100 rounded-3xl w-full h-full">
            <p className="text-center text-xl py-5 px-48">The simplest way to manage your workspace.</p>
          <div className="relative px-10">
            <img src={page_1} className="w-full drop-shadow-md rounded-xl" />
            <img src={page_2} className="absolute drop-shadow-md w-[55%] top-60 right-20 rounded-xl" />
            <img src={page_3} className="absolute drop-shadow-md w-[25%] top-56 left-2 rounded-xl" />
          </div>
        </div>
      </div>

      <div className="h-full w-[40%] flex items-center justify-center px-20">
        <div className="w-full">
          <div className="text-center pb-5">
            <p className="text-2xl font-bold text-emerald-950 drop-shadow-sm">
              Welcome Back! Please <span className="relative">Sign in</span> to continue 
            </p>
            {/* <p>Login to your account</p> */}
          </div>
          <Form
            layout="vertical"
            name="basic"
            onFinish={onSubmit}
            initialValues={formik.initialValues}
            size="large"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please enter your username!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <div className="px-0 py-3">
              <Form.Item>
                <Button type="primary" className="w-full" htmlType="submit">
                  Sign In
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>

      <Spin spinning={isLoading} fullscreen />
    </div>
  );
};

export default Login;
